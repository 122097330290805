<template>
    <div>
      <div v-if="loading">Loading .....</div>
      <div v-else>
        <CDataTable
          :items="fav_video_data"
          :fields="fields"
          :dark="false"
          :items-per-page="tableperpage"
          pagination
          sorter
          hover
          striped
          border
          small
          fixed
          light
        >
        <template #_id="{ item }">
          <td>{{item._id}}</td>
        </template>
        <template #campname="{ item }">
          <td>{{item.campname}}</td>
        </template>
        <template #url="{ item }">
          <td>{{item.url}}</td>
        </template>
          <template #action="{ item }">
            <td class="link">
              <span class="link ml-3" @click="openModal(item,'iwitness')">
                Inspector
                <!-- <small class="text-white"><CIcon name="cil-info" /></small> -->
              </span>
            </td>
          </template>
        </CDataTable>
      </div>
  <!-- Show Iwitness Model -->
    <CModal
      title="Inspector"
      :show.sync="show_iwitness"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <iwitness-modal :iwitness_data="iwitness_data" @getFav="getFav"></iwitness-modal >
      
      <template #footer>
        <CButton @click="show_iwitness = false" color="danger">Close</CButton>
      </template>
    </CModal>
    
    </div>
  </template>
  
  
  
  
  <script>
  import Iwitness from "../services/iwitnessController";
  import IwitnessModal from "./Iwitness.vue"
  export default {
    name: "FavoriteVideo",
    components: {IwitnessModal},
    props: { show_fav_video: Boolean },
    data() {
      return {
        show_iwitness:false,
        iwitness_data:[],
        fav_video: false,
        fav_video_data: [],
        fields: [
          { key: "_id", label: "Id", sortable: true },
          { key: "url", label: "Url", sortable: true },
          { key: "campname", label: "Campaign", sortable: true },
          { key: "action", label: "Action", sortable: true },
        ],
        video_modal: false,
        show_video_button: false,
        show_video: false,
        loading: false,
        uploadVStatus: false,
        videoUrl: "",
        vdata: [],
        tableperpage:10,
      };
    },
    watch: {
      show_fav_video(value) {
        this.getFav();
      },
    },
    mounted() {},
    methods: {
      async getFav() {
        let getfav = await Iwitness.getFav();
        this.fav_video_data = getfav.data;
      },
      openModal(data,txt) {
        switch(txt){
          case 'iwitness':
            this.show_iwitness = true
            this.iwitness_data = data
            break;
        }

      }
    },
  };
  </script>