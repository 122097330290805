<template>
  <div class="col-md-12">
    <CRow class="mt-3">
      <CCol xs="12" sm="12" md="12" lg="12" xl="12">
        <apex-chart v-if="show_chart" type="bar" height="350" :options="chartOptions" :series="series"></apex-chart>
        <span v-else>Loading ....</span>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import Vue from 'vue'
import DashboardService from "./../services/dashboardController";
import { CChartBar } from "@coreui/vue-chartjs";
import moment from 'moment'
import ApexChart from 'vue-apexcharts'
Vue.use(ApexChart)
export default {
  name: "OverviewChart",
  components: { CChartBar ,ApexChart},
  props: {
    show_overviewchart: {
      type: Boolean,
      required: false,
      default: false,
    },
    filter_data: {
      },
  },
  computed: {},
  watch: {
    show_overviewchart: function () {
      this.filtered_data = JSON.parse(localStorage.getItem("filter_dash1"));
      if(this.show_overviewchart){
        this.getChartData();
      }
    }
  },
  data() {
    return {
      filtered_data:this.filter_data,
      show_chart:false,
      series: [{
            name: 'normal',
            data: []
          }, {
            name: 'sus',
            data: []
          }, {
            name: 'high',
            data: [],
          }, {
            name: 'discard',
            data: []
          }
          ],
          chartOptions: {
            colors:['#4FB941', '#3C69ED', '#FF0000', '#000000'],
            chart: {
              type: 'bar',
              height: 450,
              stacked: true,
              // stackType: '100%'
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            title: {
              text: ''
            },
            xaxis: {
              categories: [],
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + ""
                }
              }
            },
            fill: {
              opacity: 1
            
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
            }
          }
    };
  },
  mounted() {
    if (localStorage.getItem("filter_dash1")) {
      this.filtered_data = JSON.parse(localStorage.getItem("filter_dash1"));
    }
  },
  methods: {
   async getChartData() {
     this.show_chart = false
      let payload = {"edate":this.filtered_data.edate,
      "sdate":this.filtered_data.sdate,"granularity":this.filtered_data.granularity,
      "dim": this.filtered_data.dim,
      "timezone":this.filtered_data.timezoneopt.value
      }
      let response = await DashboardService.fraudgr(payload);
      this.chartOptions.xaxis.categories = []
      response.message.date.map((d) => {
        this.chartOptions.xaxis.categories.push(this.changeDateFormate(d));
      });
      this.series[0].data = []
      this.series[1].data = []
      this.series[2].data = []
      this.series[3].data = []
      response.message.normal.map((normal)=>{
        this.series[0].data.push(normal)
      })
      response.message.sus.map((sus)=>{
        this.series[1].data.push(sus)
      })
      response.message.high.map((high)=>{
        this.series[2].data.push(high)
      })
      response.message.discard.map((discard)=>{
        this.series[3].data.push(discard)
      })
      this.show_chart = true
    },
    changeDateFormate(d) {
       return moment.utc(d).format('MMMM D, YYYY, HH A')
    },
  },
};
</script>