var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_vm._v("Loading .....")]):_c('div',[_c('CDataTable',{attrs:{"items":_vm.fav_video_data,"fields":_vm.fields,"dark":false,"items-per-page":_vm.tableperpage,"pagination":"","sorter":"","hover":"","striped":"","border":"","small":"","fixed":"","light":""},scopedSlots:_vm._u([{key:"_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item._id))])]}},{key:"campname",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.campname))])]}},{key:"url",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.url))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"link"},[_c('span',{staticClass:"link ml-3",on:{"click":function($event){return _vm.openModal(item,'iwitness')}}},[_vm._v(" Inspector ")])])]}}])})],1),_c('CModal',{attrs:{"title":"Inspector","show":_vm.show_iwitness,"size":"lg","centered":true,"scrollable":"","color":"dark"},on:{"update:show":function($event){_vm.show_iwitness=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.show_iwitness = false}}},[_vm._v("Close")])]},proxy:true}])},[_c('iwitness-modal',{attrs:{"iwitness_data":_vm.iwitness_data},on:{"getFav":_vm.getFav}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }